<template>
  <page-container title="维修任务" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="任务单号" prop="maintenancordernum">
              <a-input v-model.trim="queryParams.maintenancordernum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯编号" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="处理人账号" prop="processperson">
              <a-input v-model.trim="queryParams.processperson" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="节点状态" prop="processstep">
              <a-select v-model="queryParams.processstep">
                <a-select-option v-for="(item, index) in orderstepOptions" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupid">-->
<!--              <a-select v-model="queryParams.maintenancegroupid">-->
<!--                <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupname">
              <a-input v-model="queryParams.maintenancegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">维修任务</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancorderid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="maintenancordernum" slot-scope="value">
            <!-- <span v-if="record.overdue" class="table-dot" style="background-color: #ff4d4f;"></span> -->
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="creattime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="needfinishtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="processstep" slot-scope="value">
            <a-tag :color="orderstepscolorMap[value]||''">{{orderstepsMap[value]||''}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="detail(value, record, index)">详情</a-button>
            <!-- <a-button type="link" size="small" @click="liftdetail(value, record, index)">设备详情</a-button> -->
            <!-- <a-button type="link" size="small" @click="cancelConfirm(value, record, index)">撤销</a-button> -->
            <!-- <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button> -->
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'liftdetail-'+record.maintenancorderid">设备详情</a-menu-item>
                <!-- <a-menu-item :key="'cancel-'+record.maintenancorderid">撤销</a-menu-item> -->
                <a-menu-item :key="'delete-'+record.maintenancorderid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-modal :visible.sync="addModalVisible" @get-operation-result="getTableData"></add-modal>
    <detail-modal :visible.sync="detailModalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
    <liftdetail-modal :visible.sync="liftdetailModalVisible" :detail-data="modalDetailData"></liftdetail-modal>
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import orderstepOptions from '@/json/orderstepOptions'
import orderstepsMap from '@/json/ordersteps'
import orderstepscolorMap from '@/json/orderstepscolor'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import { findMaintenanceorderListByCondition, deleteMaintenanceorder, cancelMaintenanceorder } from 'A/wbgl.js'
import AddModal from './AddModal'
import DetailModal from './DetailModal'
import LiftdetailModal from './LiftdetailModal'
export default {
  name: 'repairTask',
  mixins: [deptselect, pagination],
  components: {
    AddModal,
    DetailModal,
    LiftdetailModal,
  },
  data() {
    return {
      moment,
      orderstepOptions,
      orderstepsMap,
      orderstepscolorMap,
      maintenancegroupidOptions: [],
      breadcrumb: [
        {
          name: '维保管理',
          path: ''
        },
        {
          name: '维修管理',
          path: ''
        },
        {
          name: '维修任务',
          path: ''
        },
      ],
      showAdvanced: false,
      queryParams: {
        maintenancordertype: '1',
        maintenancordernum: '',
        liftnum: '',
        liftrescue: '',

        processperson: '',
        processstep: '',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        adress: '',
        userdepname:'',
        maintenancedepname:'',
        maintenancegroupname:'',
      },
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      tableColumns: [
        {
          title: '任务单号',
          dataIndex: 'maintenancordernum',
          key: 'maintenancordernum',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenancordernum' }
        },
        {
          title: '电梯编码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '报修时间',
          dataIndex: 'creattime',
          key: 'creattime',
          ellipsis: true,
          scopedSlots: { customRender: 'creattime' }
        },
        // {
        //   title: '应完成日期',
        //   dataIndex: 'needfinishtime',
        //   key: 'needfinishtime',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'needfinishtime' }
        // },
        {
          title: '节点状态',
          dataIndex: 'processstep',
          key: 'processstep',
          ellipsis: true,
          scopedSlots: { customRender: 'processstep' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      addModalVisible: false,
      detailModalVisible: false,
      modalDetailData: null,
      liftdetailModalVisible: false,
      counts:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
      this.initMaintenancegroupidOptions();
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        isnotauto:"true",
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findMaintenanceorderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            if(item.processstep != '81') {
              if(item.needfinishtime) {
                if(moment() > moment(item.needfinishtime, 'YYYYMMDD')) {
                  item.overdue = true;
                }
              }
            }
            return item;
          });
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    add() {
      this.addModalVisible = true;
    },
    detail(value, record) {
      this.modalDetailData = record;
      this.detailModalVisible = true;
    },
    liftdetail(value, record) {
      // this.modalDetailData = record;
      // this.liftdetailModalVisible = true;
      this.$router.push({
        name: 'elevatorArchivesDetail',
        params: {
          liftid: record.liftid,
          prePages: [
            {
              name: '维修管理',
              path: ''
            },
            {
              name: '维修任务',
              path: 'repairTask'
            }
          ]
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.maintenancorderid);
        },
        onCancel() {},
      });
    },
    delete(maintenancorderid) {
      this.showLoading();
      if(maintenancorderid) {
        let params = {
          maintenancorderid
        };
        deleteMaintenanceorder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    cancelConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.cancelOrder(record.maintenancorderid);
        },
        onCancel() {},
      });
    },
    cancelOrder(maintenancorderid) {
      this.showLoading();
      if(maintenancorderid) {
        let params = {
          maintenancorderid
        };
        cancelMaintenanceorder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let maintenancorderid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenancorderid', maintenancorderid);
      if(type == 'liftdetail') {
        this.liftdetail('', record);
      }else if(type == 'cancel') {
        this.cancelConfirm('', record);
      }else if(type == 'delete') {
        this.deleteConfirm('', record);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>