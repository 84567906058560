<template>
  <a-modal v-model="modalVisible" title="选择电梯" :width="1000" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="电梯注册码" prop="liftnum">
          <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="安装地址" prop="adress">
          <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item style="float: right">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
          <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
        </a-form-model-item>
      </a-form-model>
      <div v-show="showAdvanced" class="advanced-query">
        <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
          <a-form-model-item label="区域" prop="areacode">
            <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
          </a-form-model-item>
          <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
            <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepname">
            <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="liftid" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false" class="select-modal-table">
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import { getLiftinfoListByCondition } from 'A/jcgn'
import {mapState} from "vuex";
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
export default {
  mixins: [areaselect, deptselect, pagination],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      modalVisible: false,
      showAdvanced:false,
      modalBodyStyle: {
        height: '670px',
        overflowY: 'auto',
      },
      queryAreaSelected: [],
      queryParams: {
        liftnum: '',
        liftrescue: '',
        userdepname:'',
        maintenancedepname:'',
        maintenancegroupname:'',
        adress: '',
        areacode: '',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '电梯注册代码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        {
          title: '维保组名称',
          dataIndex: 'maintenancegroupname',
          key: 'maintenancegroupname',
          ellipsis: true,
        },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.liftid);
      return currentSelectKeys;
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.modalVisible = this.visible;
      this.getTableData();
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.queryAreaSelected=[];
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getLiftinfoListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.liftid) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.liftid!=record.liftid)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.liftid) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.liftid!=record.liftid)
        })
      }
    },
    confirm() {
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.select-modal-table {
  margin-top: 15px;
}
</style>