<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="电梯注册码" prop="liftid">
        <a-input :value="liftItem?liftItem.liftnum:''" style="width: 65%;" disabled />
        <a-button type="primary" style="width: 30%;margin-left:5%;" @click="selectLiftVisible=true">选择</a-button>
      </a-form-model-item>
      <a-form-model-item label="维修类型" prop="maintenanctype">
        <a-select v-model="formDatas.maintenanctype">
          <a-select-option :value="1">普通维修</a-select-option>
          <a-select-option :value="2">困人急修</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="维修内容" prop="content">
        <a-input v-model.trim="formDatas.content" />
      </a-form-model-item>
      <!-- <a-form-model-item label="应完成时间" prop="needfinishtime">
        <a-date-picker v-model="needfinishtimeMoment" style="width: 100%;"></a-date-picker>
      </a-form-model-item> -->
      <a-form-model-item label="故障描述" prop="orderdecs">
        <a-input v-model.trim="formDatas.orderdecs" />
      </a-form-model-item>
    </a-form-model>
    <select-lift select-type="radio" :visible.sync="selectLiftVisible" :default-selected="liftItem?[liftItem]:[]" @get-selected-rows="getLiftSelectRows"></select-lift>
  </a-modal>
</template>
<script>
import moment from 'moment'
import { addMaintenaceorder } from 'A/wbgl.js'
import SelectLift from 'C/modals/SelectLift.vue'
export default {
  components: {
    SelectLift,
  },
  props: {
    visible: {
      default: false
    },
  },
  data() {
    return {
      moment,
      modalVisible: false,
      formDatas: {
        liftid: '',
        maintenanctype: 1,
        content: '',
        orderdecs: '',
      },
      formRules: {
        liftid: [{required: true, message: '请选择电梯'}],
        maintenanctype: [{required: true, message: '请选择维修类型'}],
        content: [{required: true, message: '请输入维修内容'}],
      },
      selectLiftVisible: false,
      liftItem: null,
    }
  },
  computed: {
    modalTitle() {
      return '新增'
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    liftItem(val) {
      if(val) {
        this.formDatas.liftid = val.liftid
      }else {
        this.formDatas.liftid = ''
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas() {
      this.liftItem = null;
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      this.$refs.modalForm.validate(valid => {
        if(valid) {
          this.showLoading();
          let params = {
            ...this.formDatas
          };
          addMaintenaceorder(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              this.$message.success('操作成功');
              this.$emit('get-operation-result', 'success', '操作成功');
              this.modalVisible = false;
            }else {
              this.$message.error(res.errormsg||'操作失败');
            }
          })
        }else {
          return false;
        }
      })
    },
    getLiftSelectRows(rows) {
      this.liftItem = rows[0]
    },
  }
}
</script>